import { Observer } from 'mobx-react-lite';
import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import CTAButton from '../../components/CTAButton/CTAButton';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeaturedEntriesGrid from '../../components/FeaturedEntriesGrid/FeaturedEntriesGrid';
import FeaturedProject from '../../components/FeaturedProject/FeaturedProject';
import IDPAnimationFeaturedProject from '../../components/FeaturedProject/IDPAnimationFeaturedProject';
import AnimationFeatured from '../../components/FeaturedProject/AnimationFeatured';
import GridRow from '../../components/GridRow/GridRow';
import GridRowEndColumn from '../../components/GridRow/GridRowEndColumn';
import Line from '../../components/Line/Line';
import NativeVideoWrapper from '../../components/NativeVideoWrapper/NativeVideoWrapper';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import ProjectArchive from '../../components/ProjectArchive/ProjectArchive';
import SevicePage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import Spacer from '../../components/Spacer/Spacer';
import StandardTextBlock from '../../components/StandardTextBlock/StandardTextBlock';
import TricolorMorphDef from '../../components/TricolorMorphDef/TricolorMorphDef';
import Widowless from '../../components/Widowless/Widowless';
import { Category } from '../../constants/categories.constants';
import { useFIAInfo } from '../../content/clients/fia.client';
import './animation.scss';
// import './ProjectPageMajesticIreland.scss';
import videoWebmHD from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop-hd.webm';
import videoMp4 from '../../content/images/majestic-ireland/majestic-ireland-homepage-loop.mp4';
import videoPoster from '../../content/images/majestic-ireland/majestic-ireland-screenshot-homepage-desktop-video-poster.jpg';
import { useAAAIDPAnimationInfo } from '../../content/projects/aaa-idp-animation.project';
import { useCarnetDePassagesInfo } from '../../content/projects/carnet-de-passages.project';
import { useCPDAnimationInfo } from '../../content/projects/cpd-animation.project';
import { useGCNInfo } from '../../content/projects/gcn.project';
import { useGymCoachInfo } from '../../content/projects/gymcoach.project';
import { useHopOnHopOffInfo } from '../../content/projects/hoponhopoff.project';
import { useJimOCallaghanInfo } from '../../content/projects/jim-ocallaghan.project';
import { useKLHInfo } from '../../content/projects/klh.project';
import { useMajesticIrelandInfo } from '../../content/projects/majestic-ireland.project';
import { useTBMAnimationInfo } from '../../content/projects/tbm-animation.project';
import { useUrgentAnimationInfo } from '../../content/projects/urgent-animation.project';
import { useAppContext } from '../../controllers/app.controller';
import { PageComponent } from '../../types/gatsby.types';
import { isIE } from '../../utils/browsers.utils';
import { ColorPalette } from '../../utils/colors.utils';
import { useAlexanderBoydDisplaysInfo } from '../../content/projects/alexander-boyd-displays.project';
import WordPressFeatured from '../../components/FeaturedProject/WordPressFeatured';

const ServicePageAnimation: PageComponent = props => {
  const { UI } = useAppContext();

  const fiaInfo = useFIAInfo();
  const majesticIrelandInfo = useMajesticIrelandInfo();
  const gcnInfo = useGCNInfo();
  const jimInfo = useJimOCallaghanInfo();
  // const klhInfo = useKLHInfo();
  const idpAnimationInfo = useAAAIDPAnimationInfo();
  const hoponhopoffInfo = useHopOnHopOffInfo();
  // const gymcoachInfo = useGymCoachInfo();
  const cpdAnimation = useCPDAnimationInfo();
  const tbmAnimation = useTBMAnimationInfo();
  const urgentAnimation = useUrgentAnimationInfo();
  const alexanderBoydAnimation = useAlexanderBoydDisplaysInfo();

  const featuredEntries = [
    fiaInfo,
    majesticIrelandInfo,
    gcnInfo,
    hoponhopoffInfo,
    // idpAnimationInfo,
    // cpdAnimation,
    // tbmAnimation,
    // urgentAnimation,
    // alexanderBoydAnimation,
    jimInfo,
    // klhInfo,
    // gymcoachInfo,
  ];

  return <Observer children={() => (
    <PageTemplate
    {...props}
    className="PageService"
    title="WordPress development and maintenance"
    description="Learn about the WordPress development and maintenance services provided by AxonDivision."
    backgroundColor={ColorPalette.black}
    foregroundColor={ColorPalette.ivory}
    >

      {/* <TricolorMorphDef
        mobile={{
          R: 'M325.737 112L393 181.629L299 205L325.737 112',
          G: 'M-31.567 76.75L22.5335 170.46L-5.48296 65.9295L-4.51704 65.6706L23.5 170.203V62H24.5V170.203L52.517 65.6706L53.483 65.9295L25.4665 170.46L79.567 76.75L80.433 77.25L26.3316 170.961L102.846 94.4465L103.554 95.1536L27.039 171.668L120.75 117.567L121.25 118.433L27.5402 172.534L132.071 144.517L132.329 145.483L27.7969 173.5H136V174.5H27.7969L132.329 202.517L132.071 203.483L27.5402 175.466L121.25 229.567L120.75 230.433L27.0388 176.332L103.554 252.846L102.846 253.554L26.3317 177.039L80.433 270.75L79.567 271.25L25.4665 177.54L53.483 282.071L52.517 282.329L24.5 177.797V286H23.5V177.797L-4.51704 282.329L-5.48296 282.071L22.5335 177.54L-31.567 271.25L-32.433 270.75L21.6682 177.039L-54.8464 253.554L-55.5535 252.846L20.9614 176.332L-72.75 230.433L-73.25 229.567L20.4597 175.467L-84.0705 203.483L-84.3294 202.517L20.2032 174.5H-88V173.5H20.2031L-84.3294 145.483L-84.0705 144.517L20.4597 172.533L-73.25 118.433L-72.75 117.567L20.9612 171.668L-55.5535 95.1536L-54.8464 94.4465L21.6683 170.961L-32.433 77.25L-31.567 76.75Z',
          B: 'M324 459.5C324 490.152 299.152 515 268.5 515C237.848 515 213 490.152 213 459.5C213 428.848 237.848 404 268.5 404C299.152 404 324 428.848 324 459.5Z',
        }}
        desktop={{
          R: "M878.407 93.0094L983.019 201.425L836.823 237.814L878.407 93.0094Z",
          G: "M-10.567 329.75L43.5335 423.46L15.517 318.929L16.483 318.671L44.5 423.203V315H45.5V423.203L73.517 318.671L74.483 318.929L46.4665 423.46L100.567 329.75L101.433 330.25L47.3316 423.961L123.846 347.446L124.554 348.154L48.039 424.668L141.75 370.567L142.25 371.433L48.5402 425.534L153.071 397.517L153.329 398.483L48.7969 426.5H157V427.5H48.7969L153.329 455.517L153.071 456.483L48.5402 428.466L142.25 482.567L141.75 483.433L48.0388 429.332L124.554 505.846L123.846 506.554L47.3317 430.039L101.433 523.75L100.567 524.25L46.4665 430.54L74.483 535.071L73.517 535.329L45.5 430.797V539H44.5V430.797L16.483 535.329L15.517 535.071L43.5335 430.54L-10.567 524.25L-11.433 523.75L42.6682 430.039L-33.8464 506.554L-34.5535 505.846L41.9614 429.332L-51.75 483.433L-52.25 482.567L41.4597 428.467L-63.0705 456.483L-63.3294 455.517L41.2032 427.5H-67V426.5H41.2031L-63.3294 398.483L-63.0705 397.517L41.4597 425.533L-52.25 371.433L-51.75 370.567L41.9612 424.668L-34.5535 348.154L-33.8464 347.446L42.6683 423.961L-11.433 330.25L-10.567 329.75Z",
          B: "M1173 438.5C1173 482.407 1137.41 518 1093.5 518C1049.59 518 1014 482.407 1014 438.5C1014 394.593 1049.59 359 1093.5 359C1137.41 359 1173 394.593 1173 438.5Z",
        }}
      /> */}

      <TricolorMorphDef
        mobile={{
          R: 'M117.471 73H114.059V76.3913H110.647V79.7826V83.1739V86.5652V89.9565V93.3478V96.7391V100.13V103.522V106.913V110.304H107.235V106.913H103.824H100.412H97V110.304V113.696V117.087H100.412V120.478H103.824V123.87V127.261H107.235V130.652V134.043H110.647V137.435V140.826H114.059V144.217V147.609V151H117.471H120.882H124.294H127.706H131.118H134.529H137.941H141.353H144.765H145.106H148.176H148.518V147.609V144.217V140.826H151.588V137.435V134.043V130.652H155V127.261V123.87V120.478V117.087V113.696V110.304V106.913H151.588V103.522H148.176V100.13H144.765H141.353V96.7391H137.941H134.529H131.118V93.3478H127.706H124.294V89.9565V86.5652V83.1739V79.7826V76.3913H120.882V73H117.471ZM120.882 76.3913V79.7826V83.1739V86.5652V89.9565V93.3478V96.7391V100.13V103.522V106.913V110.304H124.294V106.913V103.522V100.13V96.7391H127.706H131.118V100.13V103.522V106.913V110.304H134.529V106.913V103.522V100.13H137.941H141.353V103.522V106.913V110.304V113.696H144.765V110.304V106.913V103.522H148.176V106.913H151.588V110.304V113.696V117.087V120.478V123.87V127.261V130.652H148.176V134.043V137.435V140.826H145.106V144.217V147.609H144.765H141.353H137.941H134.529H131.118H127.706H124.294H120.882H117.471V144.217V140.826H114.059V137.435V134.043H110.647V130.652V127.261H107.235V123.87V120.478H103.824V117.087H100.412V113.696V110.304H103.824H107.235V113.696H110.647V117.087H114.059V113.696V110.304V106.913V103.522V100.13V96.7391V93.3478V89.9565V86.5652V83.1739V79.7826V76.3913H117.471H120.882Z',
          G: 'M351.471 208H348.059V211.391H344.647V214.783V218.174V221.565V224.957V228.348V231.739V235.13V238.522V241.913V245.304H341.235V241.913H337.824H334.412H331V245.304V248.696V252.087H334.412V255.478H337.824V258.87V262.261H341.235V265.652V269.043H344.647V272.435V275.826H348.059V279.217V282.609V286H351.471H354.882H358.294H361.706H365.118H368.529H371.941H375.353H378.765H379.106H382.176H382.518V282.609V279.217V275.826H385.588V272.435V269.043V265.652H389V262.261V258.87V255.478V252.087V248.696V245.304V241.913H385.588V238.522H382.176V235.13H378.765H375.353V231.739H371.941H368.529H365.118V228.348H361.706H358.294V224.957V221.565V218.174V214.783V211.391H354.882V208H351.471ZM354.882 211.391V214.783V218.174V221.565V224.957V228.348V231.739V235.13V238.522V241.913V245.304H358.294V241.913V238.522V235.13V231.739H361.706H365.118V235.13V238.522V241.913V245.304H368.529V241.913V238.522V235.13H371.941H375.353V238.522V241.913V245.304V248.696H378.765V245.304V241.913V238.522H382.176V241.913H385.588V245.304V248.696V252.087V255.478V258.87V262.261V265.652H382.176V269.043V272.435V275.826H379.106V279.217V282.609H378.765H375.353H371.941H368.529H365.118H361.706H358.294H354.882H351.471V279.217V275.826H348.059V272.435V269.043H344.647V265.652V262.261H341.235V258.87V255.478H337.824V252.087H334.412V248.696V245.304H337.824H341.235V248.696H344.647V252.087H348.059V248.696V245.304V241.913V238.522V235.13V231.739V228.348V224.957V221.565V218.174V214.783V211.391H351.471H354.882Z',
          B: 'M74.1176 475H70.7647V478.391H67.4118V481.783V485.174V488.565V491.957V495.348V498.739V502.13V505.522V508.913V512.304H64.0588V508.913H60.7059H57.3529H54V512.304V515.696V519.087H57.3529V522.478H60.7059V525.87V529.261H64.0588V532.652V536.043H67.4118V539.435V542.826H70.7647V546.217V549.609V553H74.1176H77.4706H80.8235H84.1765H87.5294H90.8824H94.2353H97.5882H100.941H101.276H104.294H104.629V549.609V546.217V542.826H107.647V539.435V536.043V532.652H111V529.261V525.87V522.478V519.087V515.696V512.304V508.913H107.647V505.522H104.294V502.13H100.941H97.5882V498.739H94.2353H90.8824H87.5294V495.348H84.1765H80.8235V491.957V488.565V485.174V481.783V478.391H77.4706V475H74.1176ZM77.4706 478.391V481.783V485.174V488.565V491.957V495.348V498.739V502.13V505.522V508.913V512.304H80.8235V508.913V505.522V502.13V498.739H84.1765H87.5294V502.13V505.522V508.913V512.304H90.8824V508.913V505.522V502.13H94.2353H97.5882V505.522V508.913V512.304V515.696H100.941V512.304V508.913V505.522H104.294V508.913H107.647V512.304V515.696V519.087V522.478V525.87V529.261V532.652H104.294V536.043V539.435V542.826H101.276V546.217V549.609H100.941H97.5882H94.2353H90.8824H87.5294H84.1765H80.8235H77.4706H74.1176V546.217V542.826H70.7647V539.435V536.043H67.4118V532.652V529.261H64.0588V525.87V522.478H60.7059V519.087H57.3529V515.696V512.304H60.7059H64.0588V515.696H67.4118V519.087H70.7647V515.696V512.304V508.913V505.522V502.13V498.739V495.348V491.957V488.565V485.174V481.783V478.391H74.1176H77.4706Z',
        }}
        desktop={{
          R: "M867 140H862V145H857V150V155V160V165V170V175V180V185V190V195H852V190H847H842H837V195V200V205H842V210H847V215V220H852V225V230H857V235V240H862V245V250V255H867H872H877H882H887H892H897H902H907H907.5H912H912.5V250V245V240H917V235V230V225H922V220V215V210V205V200V195V190H917V185H912V180H907H902V175H897H892H887V170H882H877V165V160V155V150V145H872V140H867ZM872 145V150V155V160V165V170V175V180V185V190V195H877V190V185V180V175H882H887V180V185V190V195H892V190V185V180H897H902V185V190V195V200H907V195V190V185H912V190H917V195V200V205V210V215V220V225H912V230V235V240H907.5V245V250H907H902H897H892H887H882H877H872H867V245V240H862V235V230H857V225V220H852V215V210H847V205H842V200V195H847H852V200H857V205H862V200V195V190V185V180V175V170V165V160V155V150V145H867H872Z",
          G: "M239 278H234V283H229V288V293V298V303V308V313V318V323V328V333H224V328H219H214H209V333V338V343H214V348H219V353V358H224V363V368H229V373V378H234V383V388V393H239H244H249H254H259H264H269H274H279H279.5H284H284.5V388V383V378H289V373V368V363H294V358V353V348V343V338V333V328H289V323H284V318H279H274V313H269H264H259V308H254H249V303V298V293V288V283H244V278H239ZM244 283V288V293V298V303V308V313V318V323V328V333H249V328V323V318V313H254H259V318V323V328V333H264V328V323V318H269H274V323V328V333V338H279V333V328V323H284V328H289V333V338V343V348V353V358V363H284V368V373V378H279.5V383V388H279H274H269H264H259H254H249H244H239V383V378H234V373V368H229V363V358H224V353V348H219V343H214V338V333H219H224V338H229V343H234V338V333V328V323V318V313V308V303V298V293V288V283H239H244Z",
          B: "M984 443H979V448H974V453V458V463V468V473V478V483V488V493V498H969V493H964H959H954V498V503V508H959V513H964V518V523H969V528V533H974V538V543H979V548V553V558H984H989H994H999H1004H1009H1014H1019H1024H1024.5H1029H1029.5V553V548V543H1034V538V533V528H1039V523V518V513V508V503V498V493H1034V488H1029V483H1024H1019V478H1014H1009H1004V473H999H994V468V463V458V453V448H989V443H984ZM989 448V453V458V463V468V473V478V483V488V493V498H994V493V488V483V478H999H1004V483V488V493V498H1009V493V488V483H1014H1019V488V493V498V503H1024V498V493V488H1029V493H1034V498V503V508V513V518V523V528H1029V533V538V543H1024.5V548V553H1024H1019H1014H1009H1004H999H994H989H984V548V543H979V538V533H974V528V523H969V518V513H964V508H959V503V498H964H969V503H974V508H979V503V498V493V488V483V478V473V468V463V458V453V448H984H989Z",
        }}
      />

      <DefaultPageHeader
        id="ServicePageHeader"
        animateTitle={false}
        // className="ServicesAnimationPageHeader"
        title="WordPress Development and Maintenance"
        // subtitle="This is a short subtitle"
      >
        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
            All our websites are bespoke and made to order, we prototype your design and wireframe the website pages using Figma where you can log in, view and make suggested changes prior to commencing the development phase.
            </Widowless>
          </StandardTextBlock>
          
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
              We integrate WordPress Content Management System (CMS) which empowers you to make any updates and changes to content when live. Our WordPress websites can be evolved to integrate eCommerce or eLearning capabilities. Contact us if you have any questions or want to discuss a project .
            </Widowless>
          </StandardTextBlock>

          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }

          <GridRowEndColumn>
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton>
          </GridRowEndColumn>
        </GridRow>

      </DefaultPageHeader> 

      <PageSection
      // id={'ServiceAnimationPageSectionGridRow'}>
      id='ServicePageSectionGridRow' >

        {/* <IDPAnimationFeaturedProject/> */}
        <WordPressFeatured/>
        
        <Line/> 
      {/* </PageSection>
      <PageSection id="ServicePageSectionGridRow"> */}
      
        {/* <Spacer size="xl" /> */}

         <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <h3>Mobile First Responsive Design</h3>
            <Line />
            <Widowless>Our web designs are made to be responsive and optimised for multi-size screen devices.
Desktop and Smartphone screen sizes and browser testing is part of the quality control testing prior to launch.
</Widowless>
          </StandardTextBlock>

          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <h3>SEO Optimised</h3>
            <Line />
            <Widowless>SEO is at the forefront of our development and coding processes. Loading speed optimisation, WMT setup, Google Analytics integration, and Yoast SEO Tools to aid organic rankings quickly and efficiently.</Widowless>
          </StandardTextBlock>

          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            {/* <h3>Let's Work <br />Together</h3> */}
            <h3>Hosting & Maintenance Packages</h3>
            <Line />
            <Widowless>
              We offer a range of after-launching hosting and support options.
Ongoing support on a monthly basis or on-demand options are available as part of our  
            </Widowless>
<a href="/plans" title="Care Plans">Care Plan Packages</a>
          </StandardTextBlock>

          {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && !isIE && <div />}

        </GridRow>

        {/* <Spacer size="pageMargin" /> */}
        {/* <Line/> */}

      </PageSection>     

      <PageSection id="HomepageFeaturedEntriesGridSection" className="HomepageFeaturedEntriesGridSection">

      <header className="DesignGalleryHeader">
        <h2>Projects</h2>
      </header>

      <FeaturedEntriesGrid entries={featuredEntries} />

      <Spacer size="pageMargin" />
      <Line/>

    </PageSection>

    <ContactForm preSelectedTopics={['websites']} />

    </PageTemplate>
  )} />
}

export default ServicePageAnimation