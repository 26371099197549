import { useIDPInfo } from "../../content/projects/idp.project";
import { ProjectMetaInfoMajesticIreland, useMajesticIrelandInfo } from "../../content/projects/majestic-ireland.project";
import { getEntryPagePath } from "../../utils/entry.utils";
import { AnimatedLink } from "../AnimatedLink/AnimatedLink";
import CTAButton from "../CTAButton/CTAButton";
import EntryMetaInfoHeader from "../EntryMetaInfoHeader/EntryMetaInfoHeader";
import GridRowEndColumn from "../GridRow/GridRowEndColumn";
import PageSection from "../PageSection/PageSection";
import SimpleImageFrame from "../SimpleImageFrame/SimpleImageFrame";
import Widowless from "../Widowless/Widowless";
import YouTubeEmbed from "../YouTubeEmbed/YouTubeEmbed";
import './FeaturedProject.scss';

// const FeaturedWebsites = () => {
const WordPressFeatured = () => {

  // const IDPAnimation = useIDPInfo();
  // const majesticIreland = useMajesticIrelandInfo();
  // const image = majesticIreland.gallery![0];
  return <>
    <PageSection id="FeaturedWebsitesTopSiteSection" className="FeaturedWebsitesTopSiteSection">
      <figure className="FeaturedWebsitesSectionTopSiteFigure">
        {/* {image && <AnimatedLink
          to={getEntryPagePath(ProjectMetaInfoMajesticIreland)}
          title={ProjectMetaInfoMajesticIreland.displayName}
        > */}

          {/* <div className="FeaturedProjectIframeWrapper">
            <iframe src="https://www.youtube.com/embed/2RLNU7p5DuM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div> */}

          <YouTubeEmbed
            src="https://www.youtube.com/embed/eaiQFq0RVtA"
            title="Youtube video player"
          />

          {/* <SimpleImageFrame
            image={image}
            title="Majestic Ireland Website Design: Homepage Desktop"
          /> */}
        {/* </AnimatedLink>} */}
        
        <figcaption>
          <div className="FeaturedWebsitesSectionTopSiteFigureFigcaptionHeader">
            <svg width="16" height="40" viewBox="0 0 16 40">
              <path d="M6.13208 5.27984L6.0732 5.22096L6.01866 5.2755L5.03575 7.52708L2.94431 9.6256L0.56622 7.24751L7.81373 0L15.0612 7.24751L12.6831 9.6256L10.5917 7.52708L9.6088 5.2755L9.55426 5.22096L9.49529 5.27992L9.49521 5.27992L9.49521 39.6363L6.13208 39.6363L6.13208 5.27984Z" fill="currentColor" />
            </svg>

            {/* <EntryMetaInfoHeader entry={ProjectMetaInfoMajesticIreland} /> */}

            <div className='EntryMetaInfoHeader'>
            <h2>Watch our websites reel
            </h2>
            <Widowless>Works 2020 – 2023</Widowless>
          </div>
            
          </div>
          <GridRowEndColumn>
            <CTAButton
              // to={getEntryPagePath(IDPAnimation)}
              to='/contact'
              // title={IDPAnimation.displayName}
              title="Contact us"
              drawLine="right"
              ribbons
            >Contact us</CTAButton>
          </GridRowEndColumn>
        </figcaption>
      </figure>
    </PageSection>

    {/* <QuoteSection
      id="FeaturedWebsitesQuoteSection"
      className="FeaturedWebsitesQuoteSection"
      content={[
        'Not just good enough.',
        'Let’s build a site that your customers love.'
      ]}
      addLineBefore
      addLineAfter
    /> */}
  </>
}

export default WordPressFeatured;